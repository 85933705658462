<template>
  <div class="schedule-management__page">
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          :mutipleCheck="true"
          :treeMenuName="$t('workRecord.project')"
          :searchName="$t('workRecord.findProject')"
          :projectArr="projectArr"
          isTreeStretch
          :choosedContractItem="choosedContractItem"
          :allUser="allUser"
          :leaveWarnCheck="leaveWarnCheck"
          @getChoosedContractItem="getChoosedContractItem"
          :warnKeyWord="warnKeyWord"
          :projectStatus="projectStatus"
          operationShowStatusSelect
          @transfromStatus="transfromStatus"
        />
      </div>
    </div>
    <div class="app-box" :style="{ width: tableWidth + 'px !important' }">
      <div class="top-box" ref="headerBtns">
        <div class="header">
          <div class="top-header-tit">
            <p class="tit-container">{{ $t('projectManagement.projectSchedule') }}</p>
            <div class="box">
              <div class="circle"></div>
              <p class="currentRord">{{ projectInfoItem.name }}</p>
            </div>
            <p @click="checkContractName" class="checkContractName">查看合同名称</p>
          </div>
          <div class="header-btns" v-if="judgePreservationCondition">
            <el-button v-if="scheduleRead" @click="resetData" size="medium">{{ $t('commonVariables.cancel') }}</el-button>
            <div v-if="timeAllow">
              <el-button @click="saveConfirm" class="savebtn" size="medium" type="primary">{{ $t('projectManagement.save') }}</el-button>
            </div>
            <div v-else>
              <el-tooltip class="item" effect="dark" content="请勿提前填写" placement="top">
                <el-button @click="saveConfirm" class="savebtn" size="medium" disabled>{{ $t('projectManagement.save') }}</el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box" ref="bottom-box">
        <div class="header" ref="header">
          <el-form label-position="left" class="searchForm" ref="searchForm" :model="searchForm" label-width="auto">
            <el-form-item :label="$t('projectManagement.chooseTime')">
              <el-date-picker v-model="searchForm.yearMonth" type="month" value-format="yyyy-MM" format="yyyy 年 MM 月"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="bottom-btns">
            <el-button @click="reset" class="savebtn" size="medium">{{ $t('commonVariables.reset') }}</el-button>
            <el-button @click="search" class="savebtn" size="medium" type="primary">{{ $t('commonVariables.filtrate') }}</el-button>
            <div v-if="timeAllow && !submitDisabled">
              <el-button
                v-if="scheduleManagementCommit"
                @click="confirmSubmit"
                style="margin-left: 10px"
                size="medium"
                type="primary"
                :plain="submitDisabled"
              >
                {{ $t('projectManagement.confirmSubmit') }}
              </el-button>
            </div>
            <div v-else>
              <el-tooltip class="item" effect="dark" :content="submitDisabled ? '本月已提交' : '请勿提前填写'" placement="top">
                <el-button
                  v-if="scheduleManagementCommit"
                  @click="confirmSubmit"
                  style="margin-left: 10px"
                  size="medium"
                  disabled
                  :plain="submitDisabled"
                >
                  {{ $t('projectManagement.confirmSubmit') }}
                </el-button>
              </el-tooltip>
            </div>

            <!-- <el-link class="checkDetailTit" :href="downUrl" target="_blank">查看详情 </el-link> -->
          </div>
          <div class="writeContainer">
            <span v-show="projectInfoItem.sow" class="sowWrite">{{ $t('projectManagement.sow') }}：{{ projectInfoItem.sow }}</span>
            <span class="lastWrite">最近填写时间：{{ lastFillUpDate }}</span>
          </div>
        </div>

        <el-table v-loading="tableLoading" :data="tableData" border :height="tableHeight" empty-text=" " :span-method="objectSpanMethod">
          <el-table-column
            v-for="(item, idx) in tableOperations"
            :key="idx"
            align="left"
            :width="item.width || 70"
            :show-overflow-tooltip="item.showTips || false"
          >
            <template #header>
              <div class="resizeHeader">
                <span class="desc">{{ $t('projectManagement.' + item.label + '') }}</span>
                <el-popover v-if="item.desc" placement="top-start" trigger="hover" class="showTips">
                  <span> {{ item.desc }}</span>
                  <el-button
                    :class="['unactived', { actived: hoverType }]"
                    class="btn-miss"
                    slot="reference"
                    @mouseenter.native="hoverCheck(1)"
                    @mouseleave="hoverCheck(0)"
                  >
                  </el-button>
                </el-popover>
                <el-popover
                  :ref="item.label + '-popper'"
                  popper-class="transfer-popper"
                  v-if="item.sort"
                  width="300"
                  placement="top-start"
                  trigger="click"
                  class="showTips"
                >
                  <my-transfer-panel
                    :data="transferArr(item.label)"
                    :title="$t('projectManagement.' + item.label + '')"
                    filterable
                    :isClearQuery="isClearQuery"
                    :placeholder="$t('commonVariables.enterTips') + $t('projectManagement.' + item.label + '')"
                    :default-checked="[]"
                    @checked-change="onSourceCheckedChange"
                    :format="{ noChecked: '', hasChecked: '${checked}/${total}' }"
                    :props="{
                      label: 'label',
                      key: 'key',
                      disabled: 'disabled',
                    }"
                    :check-label="item.label"
                  >
                    <slot name="right-footer">
                      <el-button type="default" @click="resetTransferData(item.label)" style="width: 54px">
                        {{ $t('commonVariables.cancel') }}
                      </el-button>
                      <el-button type="primary" @click="submitTransferData(item.label, idx)" style="width: 54px">
                        {{ $t('commonVariables.filtrate') }}
                      </el-button>
                    </slot>
                  </my-transfer-panel>
                  <GLIcon
                    slot="reference"
                    icon="icon-shaixuan1"
                    :class="['unsort', { sort: activeIndex.includes(idx) }, 'glIcon']"
                    class="el-icon-sort btn-miss"
                  />
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              <div :class="item.value === 'servingName' && scope.row.servingJustSettlement ? 'tips' : ''">
                <el-popover
                  v-if="item.value === 'servingName' && scope.row.servingRemark"
                  placement="bottom"
                  width="240"
                  trigger="hover"
                  :content="scope.row.servingRemark"
                  popper-class="poppers"
                >
                  <div slot="reference" class="popperRemarks">
                    <span>{{ scope.row[item.value] }}</span>
                  </div>
                </el-popover>
                <span v-else :style="warnStyle(item.value, scope.row[item.value])">{{ columnItem(item.value, scope.row[item.value]) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label-class-name="confirmLabel" align="left" min-width="230px">
            <template #header>
              <div class="resizeHeader">
                <span class="desc confirmLabel">{{ $t('projectManagement.workItemconfirmValue') }}</span>
                <i class="el-icon-edit"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="inputItem">
                <template v-if="!inputTip(scope.$index)">
                  <el-popover
                    v-if="scope.row.remark || scope.row.unitPrice || scope.row.unitPrice == 0"
                    placement="top"
                    width="240"
                    trigger="hover"
                    popper-class="poppers"
                  >
                    <ul>
                      <li v-for="item in getHoverText(scope)" :key="item" v-html="item"></li>
                    </ul>
                    <div slot="reference" class="popperRemarks inputShadow" @click="clickThisRow(scope.row, scope.$index)">
                      <span>{{ getShowSpan(scope) }}</span>
                    </div>
                  </el-popover>
                  <div
                    v-else
                    :class="['inputShadow', { withoutValue: scope.row.size ? false : true }]"
                    @click="clickThisRow(scope.row, scope.$index)"
                  >
                    {{ getShowSpan(scope) }}
                  </div>
                </template>
              </div>
              <div v-if="inputShow && inputTip(scope.$index)" class="scope-input">
                <el-input v-model="scope.row.size" @change="recordSize(scope.row, scope.$index)"> </el-input>

                <el-popover placement="top-end" width="270" trigger="click" popper-class="poppers" @show="popShow" @hide="popClose">
                  <div class="unitPriceDiv" v-if="scope.row.servingJustSettlement">
                    <p class="title">本期单价</p>
                    <el-input
                      type="number"
                      title=""
                      @change="recordSize(scope.row, scope.$index)"
                      placeholder="请输入本期单价"
                      v-model="scope.row.unitPrice"
                      :controls="false"
                      style="width: 176px"
                    />
                  </div>
                  <div class="remarkDiv">
                    <p class="title">备注</p>
                    <el-input
                      @change="recordSize(scope.row, scope.$index)"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder="请输入内容"
                      v-model="scope.row.remark"
                    >
                    </el-input>
                  </div>
                  <div slot="reference" class="popoverStyle">
                    <GLIcon
                      icon="icon-bianjibeizhu"
                      iconClass="icon-bianjibeizhu"
                      v-show="scope.$index === indexId && !iconColorSwitch"
                      class="bianjibeizhu"
                    ></GLIcon>
                    <GLIcon
                      icon="icon-bianjibeizhu-jizhong"
                      iconClass="icon-bianjibeizhu-jizhong"
                      v-show="scope.$index === indexId && iconColorSwitch"
                      class="bianjibeizhu"
                    ></GLIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <SimpleDialog
      @onCancel="dialogFormVisible = false"
      class="checkContractDialog"
      title="查看合同名称"
      :visible.sync="dialogFormVisible"
      width="814px"
    >
      <template>
        <div class="tableContainer">
          <el-table border empty-text=" " height="438px" :data="projectInfoItem.contractTableData" style="width: 100%">
            <el-table-column prop="contractCode" :label="$t('projectManagement.projectCode')" width="260px"> </el-table-column>
            <el-table-column prop="contractName" :label="$t('projectManagement.projectName')"> </el-table-column>
          </el-table>
        </div>
      </template>
    </SimpleDialog>
    <SimpleDialog
      showCancel
      showConfirm
      class="checkContractDialog submitContractDialog"
      :show-close="false"
      title="提示"
      confirmBtnText="提交"
      :visible.sync="dialogSubmitVisible"
      width="300px"
      @onCancel="dialogSubmitVisible = false"
      @onSubmit="submitCurrentMonthData"
    >
      <div class="des-container">
        <p>请确认提交信息：</p>
        <p>
          项目名称：<span class="boldDes">{{ projectInfoItem.name }}</span>
        </p>
        <p>
          提交月份：<span class="boldDes">{{ submitYearMonth }}</span>
        </p>
        <p>提交后不可修改对应的进度且不可撤回，请确认无误后再提交。</p>
      </div>
    </SimpleDialog>
  </div>
</template>

<script>
import projectManagementApi from '@/api/modules/projectManagement.api.js';
import scheduleManagementApi from '@/api/modules/scheduleManagement.api.js';

import { mixinsProjectStatement } from '@/mixins/authGroup';
import _ from 'lodash';
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import MyTransferPanel from '@/components/common/MyTransferPanel';
import { mixinsWorkRecord } from '@/mixins/treeMenu';
export default {
  components: {
    TreeMenu,
    MyTransferPanel,
  },
  mixins: [mixinsWorkRecord, mixinsProjectStatement],

  name: 'ScheduleManagement',
  data() {
    return {
      // 勾选栏筛选模块用
      isClearQuery: 0,
      activeIndex: [],
      contractSeqArr: [],
      serviceArr: [],
      servingManualFinshedNumArr: [],
      servingManualProgressNumArr: [],
      contractSeqNumArr: [],

      // 预填充数组
      contractSeqPreChecked: [],
      servicePreChecked: [],
      servingFinshedPreChecked: [],
      contractSeqNumPreChecked: [],
      servingProgressPreChecked: [],
      // 提交用数组
      contractSeqChecked: [],
      serviceChecked: [],
      servingFinshedNumChecked: [],
      contractSeqNumChecked: [],
      servingProgressChecked: [],
      // 勾选栏筛选模块用
      iconColorSwitch: false,
      submitYearMonth: '',
      dialogSubmitVisible: false,
      dialogFormVisible: false,
      hoverType: 0,
      submitDisabled: false,
      warnKeyWord: this.$t('projectManagement.fillinProgress'),
      leaveWarnCheck: 0,
      scheduleIndex: [],
      scheduleData: [],
      searchForm: {
        /*     page: 1,
        pageSize: 20, */
        yearMonth: '',
        projectIdEq: '',
      },
      currentRow: {},
      inputShow: false,
      indexId: null,
      otherDomHeight: 0,
      tableHeight: 0,
      allUser: [],
      tableWidth: 0,
      lastFillUpDate: '',
      choosedContractItem: null,
      tableLoading: false,
      projectArr: [], //项目列表
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',
      tableData: [],
      originTableData: [],
      tableOperations: [
        {
          label: 'projectSeq',
          value: 'contractSeq',
          showTips: true,
          width: 100,
          sort: true,
          index: 0,
        },

        {
          label: 'serviceItem',
          value: 'servingName',
          showTips: true,
          width: 170,
          sort: true,
          index: 1,
          desc: '右上角有橙色角标服务项为据实结算',
        },
        {
          label: 'finishPercent',
          value: 'finishPercent',
          showTips: false,
          width: 140,
          sort: true,
          index: 2,
        },
        {
          label: 'servingManualAmountSize',
          value: 'servingManualAmountSize',
          showTips: true,
          width: 140,
          sort: true,
          index: 3,
        },

        {
          label: 'contractSize',
          value: 'contractSize',
          width: 110,
          sort: true,
          index: 4,
        },
        {
          label: 'contractUnit',
          value: 'contractUint',
          width: 110,
        },
        {
          label: 'projectWork',
          value: 'name',
          showTips: true,
          width: 210,
        },
        {
          label: 'referenceTotalSize',
          value: 'jobAmountSize',
          desc: '  同个项目工作项下，设置的任务项数量总和',
          width: 120,
        },
        {
          label: 'referenceSize',
          value: 'referenceSize',
          desc: '同个项目工作项下，按成员所填的任务项进度（产出数量）及 设置的权重，系统汇总计算的参考值',
          width: 140,
        },
        {
          label: 'workTotalSize',
          value: 'taskAmountSize',
          desc: '设置的项目工作项数量',
          width: 120,
        },
        {
          label: 'taskManualAmountSize',
          value: 'taskManualAmountSize',
          desc: '截止本月的累计情况',
          showTips: true,
          width: 140,
        },
      ],
      projectStatus: 0,
    };
  },
  async created() {
    await this.getProject();
    await this.getSearchByUserName();
  },
  computed: {
    transferArr() {
      return label => {
        switch (label) {
          case 'projectSeq':
            return this.contractSeqArr;
          case 'contractSize':
            return this.contractSeqNumArr;
          case 'serviceItem':
            return this.serviceArr;
          case 'finishPercent':
            return this.servingManualProgressNumArr;
          case 'servingManualAmountSize':
            return this.servingManualFinshedNumArr;
          default:
            return [];
        }
      };
    },
    warnStyle() {
      return (label, value) => {
        if (label === 'finishPercent') {
          return Number(value * 100) > 100 ? 'color:#E62412' : '';
        }
      };
    },
    columnItem() {
      return (label, value) => {
        if (label === 'finishPercent' && value) {
          return _.round(value * 100, 2) + '%';
        } else {
          return value;
        }
      };
    },
    downUrl() {
      return 'https://pmf-bi-test.q2janus.com/task_schedule';
    },
    projectInfoItem() {
      return {
        sow: this.choosedContractItem?.sow ?? '',
        name: this.choosedContractItem?.name ?? '',
        id: this.choosedContractItem?.id ?? '',
        contractTableData: this.choosedContractItem?.contractNames ?? [],
      };
    },
    judgePreservationCondition() {
      return (!this.submitDisabled && (this.scheduleSave || this.scheduleForceSave)) || (this.submitDisabled && this.scheduleForceSave);
    },
    matchData() {
      return item => {
        const contractSeqResult = this.contractSeqChecked.length ? this.contractSeqChecked.includes(item.contractSeq) : true;
        const contractSeqNumResult = this.contractSeqNumChecked.length ? this.contractSeqNumChecked.includes(item.contractSize) : true;
        const serviceResult = this.serviceChecked.length ? this.serviceChecked.includes(item.servingName) : true;
        const servingProgressResult = this.servingProgressChecked.length
          ? this.servingProgressChecked.includes(_.round(item.finishPercent * 100, 2) + '%')
          : true;
        const servingFinshedNumResult = this.servingFinshedNumChecked.length
          ? this.servingFinshedNumChecked.includes(item.servingManualAmountSize)
          : true;

        if (contractSeqResult && contractSeqNumResult && serviceResult && servingProgressResult && servingFinshedNumResult) {
          return item;
        }
      };
    },
    timeAllow() {
      if (!this.submitYearMonth) return true;
      return new Date() > new Date(this.submitYearMonth);
    },
  },
  methods: {
    filterTableData() {
      const filterData = [...this.originTableData];
      this.tableData = filterData.filter(item => {
        return this.matchData(item);
      });
    },

    resetTransferData(label) {
      this.$refs[label + '-popper'][1].doClose();
      switch (label) {
        case 'projectSeq':
          this.contractSeqPreChecked = [...this.contractSeqChecked];
          break;
        case 'contractSize':
          this.contractSeqNumPreChecked = [...this.contractSeqNumChecked];

          break;
        case 'serviceItem':
          this.servicePreChecked = [...this.serviceChecked];
          break;
        case 'finishPercent':
          this.servingProgressPreChecked = [...this.servingProgressChecked];
          break;
        case 'servingManualAmountSize':
          this.servingFinshedPreChecked = [...this.servingFinshedNumChecked];
          break;
      }
    },
    clearTransferData() {
      this.activeIndex = [];
      this.contractSeqArr = [];
      this.serviceArr = [];
      this.servingManualFinshedNumArr = [];
      this.servingManualProgressNumArr = [];
      this.contractSeqNumArr = [];
      this.contractSeqPreChecked = [];
      this.servicePreChecked = [];
      this.servingFinshedPreChecked = [];
      this.contractSeqNumPreChecked = [];
      this.servingProgressPreChecked = [];
      this.contractSeqChecked = [];
      this.serviceChecked = [];
      this.servingFinshedNumChecked = [];
      this.contractSeqNumChecked = [];
      this.servingProgressChecked = [];
    },
    submitTransferData(label, idx) {
      this.$refs[label + '-popper'][1].doClose();

      switch (label) {
        case 'projectSeq':
          this.contractSeqChecked = [...this.contractSeqPreChecked];
          this.contractSeqChecked.length ? this.focusCheck(idx, 1) : this.focusCheck(idx, 0);

          break;
        case 'contractSize':
          this.contractSeqNumChecked = [...this.contractSeqNumPreChecked];
          this.contractSeqNumChecked.length ? this.focusCheck(idx, 1) : this.focusCheck(idx, 0);

          break;
        case 'serviceItem':
          this.serviceChecked = [...this.servicePreChecked];
          this.serviceChecked.length ? this.focusCheck(idx, 1) : this.focusCheck(idx, 0);

          break;
        case 'finishPercent':
          this.servingProgressChecked = [...this.servingProgressPreChecked];
          this.servingProgressChecked.length ? this.focusCheck(idx, 1) : this.focusCheck(idx, 0);

          break;
        case 'servingManualAmountSize':
          this.servingFinshedNumChecked = [...this.servingFinshedPreChecked];
          this.servingFinshedNumChecked.length ? this.focusCheck(idx, 1) : this.focusCheck(idx, 0);

          break;
      }
      this.filterTableData();
    },
    onSourceCheckedChange(val, movedKeys, label) {
      switch (label) {
        case 'projectSeq':
          this.contractSeqPreChecked = [...val];
          break;
        case 'contractSize':
          this.contractSeqNumPreChecked = [...val];
          break;
        case 'serviceItem':
          this.servicePreChecked = [...val];
          break;
        case 'finishPercent':
          this.servingProgressPreChecked = [...val];
          break;
        case 'servingManualAmountSize':
          this.servingFinshedPreChecked = [...val];
          break;
      }

      // this.$emit('left-check-change', val, movedKeys);
    },
    popShow() {
      this.iconColorSwitch = true;
    },
    popClose() {
      this.iconColorSwitch = false;
    },
    submitCurrentMonthData() {
      this.saveConfirm('defineSubmit');

      this.dialogSubmitVisible = false;
    },
    checkContractName() {
      this.dialogFormVisible = true;
    },
    hoverCheck(item) {
      this.hoverType = item;
    },
    focusCheck(idx, isCollect) {
      const filterIdx = this.activeIndex.findIndex(val => val === idx);

      if (isCollect) {
        !~filterIdx ? this.activeIndex.push(idx) : null;
      } else {
        ~filterIdx ? (this.activeIndex = this.activeIndex.splice(idx, 1)) : null;
      }
    },
    confirmSubmit() {
      if (!this.submitYearMonth) {
        this.$message.warning('请先选择需要确认提交的时间，进行筛选操作后再重新尝试确认提交');
        return;
      }
      this.dialogSubmitVisible = true;
    },
    recordSize(row, idx) {
      this.leaveWarnCheck = 1;
      if (this.scheduleIndex.includes(idx)) {
        const locationIndex = this.scheduleData.findIndex(item => {
          return item.markIndex === idx;
        });
        if (!~locationIndex) {
          return;
        }
        this.scheduleData[locationIndex] = {
          markIndex: idx,
          id: row.id,
          taskId: row.taskId,
          size: row.size,
          remark: row.remark,
          unitPrice: row.unitPrice,
          servingJustSettlement: row.servingJustSettlement,
          name: row.name,
        };
      } else {
        this.scheduleIndex.push(idx);
        this.scheduleData.push({
          markIndex: idx,
          id: row.id,
          taskId: row.taskId,
          size: row.size,
          remark: row.remark,
          unitPrice: row.unitPrice,
          servingJustSettlement: row.servingJustSettlement,
          name: row.name,
        });
      }
    },
    batchProcessArr(receiveArr, valueKey) {
      this[receiveArr] = this.tableData
        .map(v => {
          if (receiveArr === 'servingManualProgressNumArr') {
            return { key: _.round(v[valueKey] * 100, 2) + '%', label: _.round(v[valueKey] * 100, 2) + '%', disabled: false };
          } else {
            return { key: v[valueKey], label: v[valueKey], disabled: false };
          }
        })
        .reduce((acc, curr) => {
          const index = acc.findIndex(item => item.key === curr.key);
          if (index < 0) {
            acc.push(curr);
          }
          return acc;
        }, []);
      // this[defaultArr] = [...this[receiveArr]];
    },
    async search() {
      if (!this.searchForm.yearMonth) {
        this.$message.warning('请先选择时间再进行筛选');
        this.submitYearMonth = '';
        return;
      }
      const submitSearchData = async () => {
        this.noRefreshReset();
        if (this.projectArr.length) {
          this.tableLoading = true;
          const id = this.projectInfoItem.id;
          const submitForm = Object.assign({}, this.searchForm);
          submitForm.projectIdEq = id;
          await this.requestIdentifyConfirm();
          this.submitYearMonth = this.searchForm.yearMonth;
          const res = await scheduleManagementApi.getList(submitForm);
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            this.tableLoading = false;
            return;
          }
          this.tableLoading = false;
          this.tableData = [...res[0].schedules];
          this.originTableData = JSON.parse(JSON.stringify(res[0].schedules));
          // 统一处理transfer筛选栏数据
          this.batchProcessArr('contractSeqArr', 'contractSeq');
          this.batchProcessArr('serviceArr', 'servingName');
          this.batchProcessArr('servingManualFinshedNumArr', 'servingManualAmountSize');
          this.batchProcessArr('servingManualProgressNumArr', 'finishPercent');
          this.batchProcessArr('contractSeqNumArr', 'contractSize');

          this.handleTableData();
        }
      };
      if (this.scheduleData.length) {
        this.$confirm('当前有尚未保存的数据, 如切换其他月份将不做保存，请问是否继续？如需保存或提交，请取消后再操作。', '注意', {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        })
          .then(() => {
            submitSearchData();
          })
          .catch(() => {});
      } else {
        submitSearchData();
      }
    },
    reset() {
      this.searchForm = {
        yearMonth: '',
        projectIdEq: '',
      };
      this.submitYearMonth = '';
      this.tableData = [];
      this.originTableData = [];
    },
    // 合并行逻辑处理
    handleTableData() {
      let keys = []; // 唯一值的数组
      this.tableData.forEach(item => {
        if (!keys.includes(item.servingId)) {
          keys.push(item.servingId);
        }
      });

      let temp = [];
      keys.map(item => {
        // 将同一名称的数据放在相邻位置
        this.tableData.forEach(cell => {
          if (item === cell.servingId) {
            temp.push(cell);
          }
        });
      });

      this.tableData = [...temp];
      this.getSpanArr(temp);
    },
    // 构造一个SpanArr数组赋予rowspan，完成控制行合并
    getSpanArr(tempData) {
      let position; // 当前合并的行位置（连续相同名称的第一条数据位置）

      // 控制合并的数组
      this.spanArr = [];
      tempData.forEach((item, index) => {
        if (index === 0) {
          // 不处理第一行，直接从第二行开始，推进坐标(push(1))
          this.spanArr.push(1);
          position = 0;
        } else {
          if (tempData[index].servingId === tempData[index - 1].servingId && tempData[index].contractSeq === tempData[index - 1].contractSeq) {
            // 当前数据和上条数据的服务项id一致，则进行合并，否则无需处理，继续推进spanArr的坐标
            this.spanArr[position] += 1; //相同服务项id合并行数增加
            this.spanArr.push(0); //当前行消除
          } else {
            // 无需处理
            this.spanArr.push(1);
            position = index;
          }
        }
      });
    },
    objectSpanMethod({ /* row, column,  */ rowIndex, columnIndex }) {
      if (columnIndex < 5) {
        const _row = this.spanArr[rowIndex]; // 合并行数
        const _col = _row > 0 ? 1 : 0; // 合并列数，1：不改变，0：被消除
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    // 合并行逻辑处理
    inputTip(idx) {
      return this.indexId === idx && this.judgePreservationCondition;
    },
    clickThisRow(row, idx) {
      if (!row.servingJustSettlement && (row.disabled || !row.taskAmountSize)) return;
      this.iconColorSwitch = false;
      this.currentRow = row;
      this.indexId = idx;
      this.inputShow = true;
    },
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - this.$refs['tree-sub'].offsetWidth - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    calTableDom() {
      this.$nextTick(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 16 + 12 + 5 + 24;
        this.tableHeight = window.innerHeight - this.otherDomHeight;
      });
    },
    saveConfirm(defSub) {
      if (!this.scheduleData.length && defSub !== 'defineSubmit') {
        this.$message.warning('没有待提交的已编辑项');
        return;
      }
      let submitData = [];
      /* 初始化没有填写值的size以及去除markIndex(后端不需要markIndex，这个是前端拿来标记填写数据用的) */
      for (let i = 0; i < this.scheduleData.length; i++) {
        submitData[i] = JSON.parse(JSON.stringify(this.scheduleData[i]));
        submitData[i].markIndex = null;
        if (typeof this.scheduleData[i].size === 'undefined' || this.scheduleData[i].size === '') submitData[i].size = null;
        if (typeof this.scheduleData[i].unitPrice === 'undefined' || this.scheduleData[i].unitPrice === '') submitData[i].unitPrice = null;
      }
      let errorInfo = '';
      submitData.forEach(item => {
        if (item.servingJustSettlement && (item.size === null || item.unitPrice === null)) {
          errorInfo += item.name + ',';
        }
      });
      if (errorInfo.length) {
        this.$message.error(`请填写以下据实结算工作项单价及数量：${errorInfo.slice(0, errorInfo.length - 1)}`);
        return;
      }
      const submitFn = async () => {
        this.noRefreshReset();
        await this.search();
        await scheduleManagementApi.getRecentSchedule({ projectIdEq: this.projectInfoItem.id }).then(res => {
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            return;
          }
          this.lastFillUpDate = res[0].lastYearMonth;
        });
      };
      scheduleManagementApi.batchSaveSchedule({ schedules: submitData, yearMonth: this.submitYearMonth }).then(async res => {
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          return;
        }
        if (defSub === 'defineSubmit') {
          await scheduleManagementApi.saveSchedule({ projectIdEq: this.projectInfoItem.id, yearMonth: this.submitYearMonth }).then(res => {
            if (res[2].status !== 200) {
              this.$message.error(res[1].message);
              return;
            }
            submitFn();
            this.$message.success('确认提交成功');
          });
        } else {
          submitFn();
          this.$message.success('保存成功');
        }
      });
    },
    resetData() {
      this.resetFieldData();
    },
    resetFieldData() {
      if (this.scheduleData.length) {
        this.$confirm('你有已编辑的填写进度(数量)尚未保存，确定要提交吗？提交后后未保存的填写进度(数量)数据将丢失', '注意', {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        })
          .then(() => {
            this.noRefreshReset();
            this.search();
          })
          .catch(() => {});
      } else {
        this.noRefreshReset();
        this.search();
      }
    },
    // 确认是否已经确认提交
    requestIdentifyConfirm() {
      scheduleManagementApi.identitySubmitSchedule({ projectIdEq: this.projectInfoItem.id, yearMonth: this.searchForm.yearMonth }).then(res => {
        if (res[2].status !== 200) {
          this.submitDisabled = true;
          return;
        }
        this.submitDisabled = res[0].disabled;
        return;
      });
    },
    noRefreshReset() {
      this.scheduleData = [];
      this.scheduleIndex = [];
      this.currentRow = {};
      this.inputShow = false;
      this.indexId = null;
      this.tableData = [];
      this.leaveWarnCheck = 0;
    },
    //获取选择的那个项目
    async getChoosedContractItem(i) {
      this.isClearQuery = 1;

      this.choosedContractItem = Object.assign({}, i);
      scheduleManagementApi.getRecentSchedule({ projectIdEq: this.projectInfoItem.id }).then(res => {
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          return;
        }
        this.lastFillUpDate = res[0].lastYearMonth;
        this.isClearQuery = 0;
      });
      this.searchForm = {
        yearMonth: '',
        projectIdEq: '',
      };
      this.submitYearMonth = '';
      this.clearTransferData();
      this.noRefreshReset();
    },
    //按名字检索用户
    async getSearchByUserName() {
      if (!this.scheduleRead) {
        return;
      }
      let [data] = await projectManagementApi.getSearchUserName({ page: 1, pagesize: 99999 });
      if (data) this.allUser = data.user;
    },
    //获取所有项目列表
    async getProject(getFirstData = true) {
      if (!this.scheduleRead) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      });
      projectManagementApi.getAdminProjectInProgress({ leadIdEq: this.$store.state.user.id, statusEq: this.projectStatus }).then(async result => {
        if (result[2].status !== 200) {
          this.$message.error(result[1].message);
          loading.close();
          return;
        }
        if (!result[0].projects.length) {
          loading.close();
          return;
        }
        loading.close();
        this.projectArr = [...result[0].projects];
        //默认获取第一个合同的数据
        if (getFirstData) {
          this.choosedContractItem = this.projectArr[0];
        }
        /*         scheduleManagementApi.getRecentSchedule({ projectIdEq: this.projectInfoItem.id }).then(res => {
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            return;
          }
          this.lastFillUpDate = res[0].lastYearMonth;
        }); */
      });
    },
    // 获取展示文本
    getShowSpan(scope) {
      if (scope.row.size || scope.row.size === 0) return scope.row.size;
      if (scope.row.servingJustSettlement) return '据实结算';
      if (scope.row.disabled && scope.row.taskAmountSize === 0) return '请先在项目管理设置标准项及数量';
      if (scope.row.disabled && scope.row.taskAmountSize != 0) return '请先在项目管理设置标准项';
      if (!scope.row.disabled && scope.row.taskAmountSize === 0) return '请先在项目管理设置数量';
      return '请参考工作项总量填写';
    },
    // 获取 hover 后的文本
    getHoverText(scope) {
      let content = [];
      // 显示单价（包括0）
      if (scope.row.unitPrice || scope.row.unitPrice == 0) {
        content.push(`
        <span style="font-weight: bold; color: #515a6e">本期单价：<span/><span style="color: #515a6e; font-weight: normal">${scope.row.unitPrice}</span>`);
      }
      // 显示备注
      if (scope.row.remark) {
        content.push(`
        <span style="font-weight: bold; color: #515a6e">备注：<span/><span style="color: #515a6e; font-weight: normal">${scope.row.remark}</span>`);
      }
      // 如果行数为2，插入换行符
      if (content.length === 2) {
        content.splice(1, 0, '<br />');
      }
      return content;
    },
    transfromStatus(val) {
      this.projectStatus = val;
      this.getProject(true);
    },
  },
};
</script>
<!-- <style lang="scss">
.el-popover {
  color: #515a6e;
}
</style> -->
<style lang="scss" scoped>
::v-deep .el-table__fixed-right {
  border-left: 1px solid #e8eaec;
}
::v-deep .el-popover--plain {
  padding: 18px 20px;
}
.poppers {
  padding-top: 0;
  .title {
    margin: 4px 0;
    margin-top: 0;
    font-size: 14px;
    margin-left: 2px;
    font-weight: bold;
  }
  .el-textarea {
    font-family: MicrosoftYahei, Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    width: 245px;
  }
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 32px;
  line-height: 32px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 14px;
}
::v-deep .el-form-item__error {
  padding-top: 0;
  padding-bottom: 12px;
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
::v-deep .el-icon-arrow-up {
  line-height: 32px;
}
::v-deep .el-icon-date {
  line-height: 32px;
}
.schedule-management__page {
  max-height: 100%;
  display: flex;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .app-box {
    flex: 1;
    margin: 0 16px;
    background-color: #fff;
    overflow-y: hidden;
    border-radius: 2px;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 12px;
      height: 51px;
      .searchForm {
        display: flex;
        ::v-deep .el-form-item {
          margin-bottom: 0;
          margin-right: 24px;
        }
        ::v-deep .el-form-item__label {
          font-weight: 400;
          color: #515a6e;
          display: inline-block;
        }
      }

      .bottom-btns {
        margin-left: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        .savebtn {
          padding: 0 20px;
          height: 32px;
        }
        .checkDetailTit {
          cursor: pointer;
          font-size: 14px;
          color: #2d8cf0;
          margin-left: 20px;
          line-height: 36px;
        }
      }
      .writeContainer {
        margin-left: auto;
      }
      .sowWrite {
        margin-right: 16px;
        font-size: 14px;
        color: #808694;
      }
      .lastWrite {
        font-size: 14px;
        color: #808694;
      }
      .checkHeaderTit {
        cursor: pointer;
        font-size: 14px;
        color: #2d8cf0;
        margin-right: auto;
        padding-left: 10px;
        line-height: 36px;
      }
      .checkHeaderBtn {
        height: 36px;
      }
      .header-btns {
        margin-left: auto;
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .savebtn {
          margin-left: 16px;
        }
      }
    }

    .top-box {
      background-color: #fff;
      padding: 12px 24px 32px;
      padding-bottom: 8px;
      border-radius: 2px;
      .header {
        border-bottom: 1px solid #e8eaec;

        .top-header-tit {
          font-size: 16px;
          font-weight: 600;
          color: #19233b;
          p {
            display: inline-block;
            margin: 0;
          }
          .tit-container {
            margin: 0;
            display: inline-block;
          }
          .box {
            display: inline-block;
            position: relative;
            // min-width: 92px;
            min-height: 24px;
            margin-left: 8px;

            background: #f5f7fa;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2d8cf0;

            .circle {
              position: absolute;
              top: calc(50% - 3px);
              left: 12px;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background: #2d8cf0;
            }
            .currentRord {
              color: #515a6e;

              padding-left: 24px;
              padding-right: 12px;
              line-height: 24px;
            }
          }
          .checkContractName {
            font-weight: normal;
            font-size: 14px;
            color: #2d8cf0;
            cursor: pointer;
            margin-left: 12px;
          }
        }
      }
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
    .bottom-box {
      padding: 12px 24px 12px;
      ::v-deep .el-table__body {
        width: 100%;
        table-layout: fixed !important;
      }
      ::v-deep .el-select {
        width: 100%;
      }
      ::v-deep .el-date-editor--daterange {
        width: 100%;
      }
      .projInput {
        width: 230px !important;
      }
    }
  }
  .checkContractDialog {
    height: 700px;
    .tableContainer {
      padding: 32px;
      padding-top: 20px;
      overflow-y: auto;

      ::v-deep .el-table__header {
        width: 100%;
      }
    }
  }
  .submitContractDialog {
    ::v-deep .el-dialog__footer {
      .footer {
        .el-button {
          width: 57px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          padding: 0;
        }
      }
    }
    .des-container {
      margin: 17px 20px 16px;
      p,
      span {
        margin: 0;
        padding: 0;
        padding-bottom: 4px;
        font-size: 14px;
        color: #515a6e;
      }
      .boldDes {
        font-weight: bold;
      }
    }
    ::v-deep .el-dialog__header {
      height: auto;
      border: none;
      padding: 20px;
      padding-bottom: 17px;
      justify-content: flex-start;
    }
  }
  ::v-deep .el-table {
    width: 100%;
    overflow-y: hidden;
    .confirmLabel {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
    }
    .resizeHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .desc {
        display: inline-block;
        margin-top: 0;
        margin-right: 4px;
      }

      .showTips {
        padding-top: 2px;
        .btn-miss {
          border: 0 !important;
          background: #f8f8f8f9;
        }
        .unactived {
          position: relative;
          cursor: pointer;
          padding: 0;
          border-radius: 4px;
          border: 1px solid #e8e8e8;
        }
        .actived {
          border: 1px solid rgba(24, 144, 255, 0.2);
        }
        .sort {
          color: #409eff;
          border: 1px solid rgba(24, 144, 255, 0.2);
        }
        .unsort {
          position: relative;
          cursor: pointer;
          padding: 0;
          // border-radius: 4px;
          border: 1px solid #e8e8e8;
        }
        .unactived::before {
          content: '';
          content: '';
          position: absolute;
          left: 30%;
          transform: translateY(-70%);
          width: 12px;
          height: 15px;
          background: url('../../assets/tabIcon/tip.png') no-repeat;
          background-size: cover;
        }
        .unactived:hover::before {
          vertical-align: center;
          background: url('../../assets/tabIcon/tip_actived.png') no-repeat;
          background-size: cover;
        }
        .glIcon {
          outline: none;
          font-size: 0.9em !important;
        }
      }
    }
    .popperRemarks {
      display: inline-block;
      width: 100%;
      height: 29px;
      background: rgba(24, 144, 255, 0.05);
      border-radius: 2px;
      font-size: 14px;
      text-align: left;
      color: #2d8cf0;
      line-height: 29px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .warnTipColor {
      color: #e62412;
    }
    .header-row-cell {
      border-bottom: none !important;
    }
    .el-table__header-wrapper {
      // border-bottom: 1px solid #e8eaec;
      border-radius: 4px;
      .el-table__header th.el-table__cell {
        background-color: #f8f8f8f9;
        color: #515a6e;
        font-weight: 600;
        padding: 6px 0;
        height: 48px;
        box-sizing: border-box;
        // border-bottom: 1px solid #ebeef5;
      }
    }

    .inputItem {
      cursor: pointer;

      .inputShadow {
        width: 100%;
        height: 29px;
        line-height: 29px;
        text-align: left;
        padding-left: 15px;
        background: #f5f7fa;
      }
      .withoutValue {
        font-size: 10px;

        color: #c0c4cc;
      }
      .hasValue {
        color: #515a6e;
      }
      .inputShadow:hover {
        border: none;
        text-align: left;
        background-color: #fff;
      }
    }
    .scope-input {
      width: 90%;
      text-align: left;
      position: relative;

      .el-input__inner {
        width: 100%;
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }

      .popoverStyle {
        position: absolute;
        top: 22%;
        right: 0%;
        .bianjibeizhu {
          font-size: 1.25em !important;
        }
      }
    }
    //table_body
    .el-table__body td.el-table__cell {
      cursor: pointer;
      padding: 6px 0;
      height: 49px;
    }
  }
}
.tips::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 4px solid #f59a23;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.unitPriceDiv {
  display: flex;
  .title {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    margin-right: 10px;
  }
  ::v-deep input[type='number']::-webkit-inner-spin-button,
  ::v-deep input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  ::v-deep .writeContainer {
    .sowWrite,
    .lastWrite {
      display: block;
    }
  }
}
</style>
<style lang="scss">
.transfer-popper {
  padding: 0;
}
</style>
